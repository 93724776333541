import get from 'lodash.get';

export function getTranslations(
  localizations?: Record<string, Record<string, string>>,
  language = 'en'
): Record<string, string> | null {
  if (!localizations) return null;

  return get(localizations, language);
}
